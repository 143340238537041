import {
  queryServices,
  queryLocations,
  queryCategories,
} from '@wix/ambassador-bookings-services-v2-service/http';
import {
  ListSchedulesRequest,
  ScheduleServer,
  Schedule,
  ScheduleStatus,
} from '@wix/ambassador-schedule-server/http';
import {
  QueryServicesRequest,
  LocationType,
  SortOrder,
  Service,
  V2Category,
  RequestedFields,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceListSettings } from '../../legacy/appSettings/appSettings';
import { buildQueryServicesFilter } from '../utils/filters/buildQueryServicesFilter';
import {
  ServicesCatalogServer,
  Schedule as ServiceSchedule,
} from '@wix/ambassador-services-catalog-server/http';
import {
  getInstance,
  getServerBaseUrl,
  manageWarmupData,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { NotificationsServer } from '@wix/ambassador-notifications-server/http';
import { ALL_SERVICES, BOOKINGS_FES_BASE_DOMAIN } from '../consts';
import { isServiceConnectedToPricingPlan } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { WeekDay } from '@wix/bookings-uou-domain';
import { getRequestedPageFromQueryParam } from '../utils/pagination/pagination';
import settingsParams from '../components/BookOnline/settingsParams';
import { FilterServicesByOptions } from '../types/types';

export const enum pricingPlanConst {
  PAGE_NOT_INSTALLED = 'PageNotInstalled',
  NO_PLANS_ASSIGNED_TO_OFFERING = 'NoPlansAssignedToOffering',
}

type QueryServicesRequestQuery = QueryServicesRequest['query'];
type QueryServicesRequestFilters = QueryServicesRequestQuery['filter'];
type QueryServicesRequestPaging = QueryServicesRequestQuery['paging'];

export type QueryServicesFilter = {
  staffMemberIds?: string[];
  categoryIds?: string[];
  serviceIds?: string[];
  locationIds?: (string | typeof ReservedLocationIds.OTHER_LOCATIONS)[];
  limit?: number;
};

export type ServiceAvailabilityMap = {
  [id: string]: { spotsLeft: number };
};

export const CATALOG_SERVER_URL = '_api/services-catalog';
const NOTIFICATIONS_SERVER_URL = '_api/notifications-server';
const SCHEDULE_SERVER_URL = '_api/schedule-reader-server';
const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
const REVISION_HEADER_NAME = 'x-wix-site-revision';

const SCHEDULES_REQUEST = 100;

export class BookingsAPI {
  private static cache = new Map();
  private flowAPI: ControllerFlowAPI;
  private appSettings: ServiceListSettings;
  private shouldWorkWithAppSettings: boolean;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private notificationsServer: ReturnType<typeof NotificationsServer>;
  private scheduleServer: ReturnType<typeof ScheduleServer>;
  private readonly isUseWarmupDataInServiceListEnabled: boolean;

  constructor({
    appSettings,
    flowAPI,
    shouldWorkWithAppSettings,
  }: {
    flowAPI: ControllerFlowAPI;
    appSettings: ServiceListSettings;
    shouldWorkWithAppSettings: boolean;
  }) {
    const serverBaseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });

    this.flowAPI = flowAPI;
    this.appSettings = appSettings;
    this.shouldWorkWithAppSettings = shouldWorkWithAppSettings;
    this.catalogServer = ServicesCatalogServer(
      `${serverBaseUrl}${CATALOG_SERVER_URL}`,
    );
    this.notificationsServer = NotificationsServer(
      `${serverBaseUrl}${NOTIFICATIONS_SERVER_URL}`,
    );
    this.scheduleServer = ScheduleServer(
      `${serverBaseUrl}${SCHEDULE_SERVER_URL}`,
    );

    this.isUseWarmupDataInServiceListEnabled = this.flowAPI.experiments.enabled(
      'specs.bookings.useWarmupDataInServiceList',
    );
  }

  static clearCache() {
    BookingsAPI.cache.clear();
  }

  private get authorization() {
    return getInstance({
      wixCodeApi: this.flowAPI.controllerConfig.wixCodeApi,
      appParams: this.flowAPI.controllerConfig.appParams,
    });
  }

  private async withCache<T>(
    key: string,
    request: () => Promise<T> | T,
    { useWarmupData }: { useWarmupData?: boolean } = {},
  ): Promise<T> {
    const cachedResult = BookingsAPI.cache.get(key);

    if (cachedResult) {
      return cachedResult;
    }

    const result = useWarmupData
      ? await manageWarmupData(request, key, this.flowAPI)
      : await request();
    BookingsAPI.cache.set(key, result);

    return result;
  }

  private buildQueryServicesFilterPayload({
    categoryIds,
    locationIds,
    serviceIds,
    staffMemberIds,
    selectedFilterOptionId,
  }: QueryServicesFilter & { selectedFilterOptionId?: string }) {
    let filter: QueryServicesRequestFilters = {};
    const hiddenFilter = {
      $or: [{ hidden: false }, { hidden: { $exists: false } }],
    };
    const orFilters: QueryServicesRequestFilters[] = [hiddenFilter];

    if (staffMemberIds) {
      filter.staffMemberIds = {
        $hasSome: staffMemberIds,
      };
    }

    const serviceIdsFilter = {
      id: {
        $in: serviceIds,
      },
    };
    const categoryIdsFilter = {
      'category.id': {
        $in: categoryIds,
      },
    };
    if (serviceIds && categoryIds) {
      const filterServicesBy =
        this.appSettings?.CATEGORIES_TYPE ||
        this.flowAPI.settings.get(settingsParams.filterServicesBy);
      const isInSpecificCategoryTab =
        selectedFilterOptionId &&
        selectedFilterOptionId !== ALL_SERVICES &&
        filterServicesBy === FilterServicesByOptions.CATEGORIES;

      const operator =
        this.flowAPI.experiments.enabled(
          'specs.bookings.fetchTabsInServiceList',
        ) &&
        this.flowAPI.experiments.enabled(
          'specs.bookings.useAndOperatorOnSpecificCategoryTab',
        ) &&
        isInSpecificCategoryTab
          ? '$and'
          : '$or';

      orFilters.push({
        [operator]: [serviceIdsFilter, categoryIdsFilter],
      });
    } else if (serviceIds) {
      filter = {
        ...filter,
        ...serviceIdsFilter,
      };
    } else if (categoryIds) {
      filter = {
        ...filter,
        ...categoryIdsFilter,
      };
    }

    if (locationIds) {
      const includeNonBusinessLocations = locationIds.includes(
        ReservedLocationIds.OTHER_LOCATIONS,
      );
      const businessLocationIds = locationIds.filter(
        (locationId) => locationId !== ReservedLocationIds.OTHER_LOCATIONS,
      );

      const otherLocationsFilter = {
        'locations.type': {
          $hasSome: [LocationType.CUSTOM, LocationType.CUSTOMER],
        },
      };
      const businessLocationFilter = {
        'locations.business.id': { $hasSome: businessLocationIds },
      };

      if (includeNonBusinessLocations && businessLocationIds.length > 0) {
        orFilters.push({ $or: [businessLocationFilter, otherLocationsFilter] });
      } else if (includeNonBusinessLocations) {
        filter = { ...filter, ...otherLocationsFilter };
      } else {
        filter = { ...filter, ...businessLocationFilter };
      }
    }

    if (orFilters.length > 1) {
      filter.$and = orFilters;
    } else {
      const [f] = orFilters;
      filter.$or = (f as any).$or;
    }
    return filter;
  }

  async queryServices({
    selectedFilterOptionId,
  }: {
    selectedFilterOptionId?: string;
  } = {}) {
    const page = getRequestedPageFromQueryParam(
      this.flowAPI.controllerConfig.wixCodeApi,
    );

    const servicesPerPage = this.flowAPI.settings.get(
      settingsParams.servicesPerPage,
    ) as number;
    const offset = (page - 1) * servicesPerPage;
    const filters = buildQueryServicesFilter({
      flowAPI: this.flowAPI,
      shouldWorkWithAppSettings: this.shouldWorkWithAppSettings,
      appSettings: this.appSettings,
      selectedFilterOptionId,
    });

    const sort = [
      {
        fieldName: 'category.sortOrder',
        order: SortOrder.ASC,
      },
      {
        fieldName: 'sortOrder',
        order: SortOrder.ASC,
      },
    ];

    const paging: QueryServicesRequestPaging = {
      limit: filters.limit || servicesPerPage,
      offset,
    };

    const response = await this.withCache(
      `queryServices-${JSON.stringify(filters)}-limit:${paging.limit}-offset:${
        paging.offset
      }`,
      () =>
        this.flowAPI.httpClient
          .request(
            queryServices({
              conditionalFields: [RequestedFields.STAFF_MEMBER_DETAILS],
              query: {
                sort,
                paging,
                filter: this.buildQueryServicesFilterPayload({
                  ...filters,
                  selectedFilterOptionId,
                }),
              },
            }),
          )
          .then((queryServicesResponse) => queryServicesResponse.data),
      { useWarmupData: this.isUseWarmupDataInServiceListEnabled },
    );

    if (this.isUseWarmupDataInServiceListEnabled) {
      response.services?.forEach((service) => {
        if (service.schedule) {
          service.schedule.firstSessionStart = convertWarmupDateStringToDate(
            service.schedule?.firstSessionStart,
          );
          service.schedule.lastSessionEnd = convertWarmupDateStringToDate(
            service.schedule?.lastSessionEnd,
          );
        }
      });
    }

    return response;
  }

  getBusinessInfo() {
    return this.withCache(
      'getBusinessInfo',
      () =>
        this.catalogServer
          .BusinessCatalog()({ Authorization: this.authorization })
          .get({ suppressNotFoundError: false }),
      { useWarmupData: this.isUseWarmupDataInServiceListEnabled },
    );
  }

  async notifyOwnerNonPremiumEnrollmentAttempt({
    service,
  }: {
    service: Service;
  }) {
    return this.notificationsServer
      .NotificationsSettings()({ authorization: this.authorization })
      .missedBooking({ serviceType: service.type });
  }

  notifyOwnerNonPricingPlanEnrollmentAttempt({
    isPricingPlanInstalled,
    service,
  }: {
    service: Service;
    isPricingPlanInstalled: boolean;
  }) {
    const reasons: pricingPlanConst[] = [];
    const offeringId = service.id;

    if (!isPricingPlanInstalled) {
      reasons.push(pricingPlanConst.PAGE_NOT_INSTALLED);
    }
    if (!isServiceConnectedToPricingPlan({ payment: service.payment! })) {
      reasons.push(pricingPlanConst.NO_PLANS_ASSIGNED_TO_OFFERING);
    }

    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      { offeringId, reasons },
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]:
            this.flowAPI.controllerConfig.wixCodeApi.site.revision,
          [XSRF_HEADER_NAME]:
            this.flowAPI.controllerConfig.platformAPIs.getCsrfToken(),
        },
      },
    );
  }

  async getOfferedDays(serviceIds: string[]) {
    return this.withCache(
      `getOfferedDays-${serviceIds.join(',')}`,
      async () => {
        const response = await this.catalogServer
          .ServicesCatalog()({ Authorization: this.authorization })
          .query({
            query: {
              paging: {
                limit: 500,
              },
              filter: {
                'service.id': {
                  $in: serviceIds,
                },
              },
            },
          });

        const services = response?.services || [];

        return services.reduce<Record<string, WeekDay[]>>((acc, service) => {
          const schedule = service.schedules?.find(
            (serviceSchedule) =>
              serviceSchedule.status === ScheduleStatus.CREATED,
          );

          const serviceId = service.service?.id!;
          const offeredDays = schedule ? mapDays(schedule) : [];

          acc[serviceId] = offeredDays;
          return acc;
        }, {});
      },
    );
  }

  async getServicesAvailability(serviceIds: string[]) {
    return this.withCache(
      `getServicesAvailability-${serviceIds.join(',')}`,
      async () => {
        const schedulesService = this.scheduleServer.Schedules();
        let schedules: Schedule[] = [];

        for (let i = 0; i < serviceIds.length; i += SCHEDULES_REQUEST) {
          const partialScheduleOwnerIds = serviceIds.slice(
            i,
            i + SCHEDULES_REQUEST,
          );
          const listSchedulesRequest: ListSchedulesRequest = {
            scheduleOwnerIds: partialScheduleOwnerIds,
            includeTotalNumberOfParticipants: this.flowAPI.experiments.enabled(
              'specs.bookings.availabilityIncludeTotalNumberOfParticipants',
            ),
          };
          const { schedules: partialSchedules } = await schedulesService({
            authorization: this.authorization,
          }).list(listSchedulesRequest);
          schedules = [...schedules, ...(partialSchedules || [])];
        }

        return schedules.reduce<ServiceAvailabilityMap>((acc, s) => {
          if (s.status === ScheduleStatus.CREATED) {
            acc[s?.scheduleOwnerId!] = {
              spotsLeft: s?.capacity! - s?.totalNumberOfParticipants!,
            };
          }
          return acc;
        }, {});
      },
    );
  }

  async queryCategories(): Promise<V2Category[] | undefined> {
    const filters = buildQueryServicesFilter({
      flowAPI: this.flowAPI,
      shouldWorkWithAppSettings: this.shouldWorkWithAppSettings,
      appSettings: this.appSettings,
      ignorePreSelectedCategories: true,
    });
    const filterPayload = this.buildQueryServicesFilterPayload(filters);
    return this.withCache(
      `queryCategories-${JSON.stringify(filters)}`,
      () =>
        this.flowAPI.httpClient
          .request(
            queryCategories({
              filter: {
                services: filterPayload,
                categoryIds: filters.categoryIds,
              },
            }),
          )
          .then((response) => response.data.categories),
      { useWarmupData: this.isUseWarmupDataInServiceListEnabled },
    );
  }

  async queryLocations() {
    const filters = buildQueryServicesFilter({
      flowAPI: this.flowAPI,
      shouldWorkWithAppSettings: this.shouldWorkWithAppSettings,
      appSettings: this.appSettings,
      ignorePreSelectedLocations: true,
    });
    const filterPayload = this.buildQueryServicesFilterPayload(filters);
    return this.withCache(
      `queryLocations-${filters}`,
      () =>
        this.flowAPI.httpClient
          .request(
            queryLocations({
              filter: {
                services: filterPayload,
                businessLocationIds: filters.locationIds?.filter(
                  (id) => id !== ReservedLocationIds.OTHER_LOCATIONS,
                ),
              },
            }),
          )
          .then((response) => response.data),
      {
        useWarmupData: this.isUseWarmupDataInServiceListEnabled,
      },
    );
  }
}

function mapDays(schedule: ServiceSchedule) {
  const nonExpiredIntervals =
    schedule?.intervals?.filter(
      (interval) =>
        !interval.end || new Date(interval.end).valueOf() > Date.now(),
    ) || [];

  const daysSet = new Set(
    nonExpiredIntervals
      .map((recurringInterval) =>
        recurringInterval?.interval?.daysOfWeek?.toLowerCase(),
      )
      .filter(Boolean),
  );

  return Array.from(daysSet) as WeekDay[];
}

const convertWarmupDateStringToDate = (stringDate: string | Date | undefined) =>
  (stringDate && new Date(stringDate)) as Date;
